"use client";

import React, { useRef } from "react";
import clsx from "clsx";
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	RowData,
	useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import { DataTableSortState } from "../DataTable";
import { useDataTableSortHelpers } from "../DataTable/hooks/internal";
import { getColumnWidth, getMetaWithDefaults } from "../DataTable/utils";
import { Table } from "../Table";
import { Skeleton } from "../progress";

interface ColumnMeta<
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TData extends RowData,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TValue,
	TSortField extends string,
> {
	align?: "left" | "center" | "right";
	sortField?: TSortField;
	truncate?: boolean;
}

type TableProps<
	TData extends { id: string },
	TSortField extends string = string,
> = {
	data: TData[];
	columns: ColumnDef<TData, any>[];
	isLoading?: boolean;
	loadingRowCount?: number;
	"data-testid"?: string;
	emptyState?: React.ReactNode;
	/* maximum height when table overflow - header not included */
	maxHeight?: number | string;
	sort?: DataTableSortState<TSortField>;
	onSortChange?: (value: DataTableSortState<TSortField>) => void;
	options?: Partial<VirtualizerOptions<HTMLDivElement, Element>>;
};

const ESTIMATED_ROW_HEIGHT = 64;

export function VirtualTable<
	TData extends { id: string },
	TSortField extends string = string,
>({
	data,
	columns,
	sort,
	onSortChange,
	isLoading = false,
	loadingRowCount = 5,
	"data-testid": testId,
	emptyState = null,
	maxHeight = "500px",
	options,
}: TableProps<TData, TSortField>) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getRowId: (row) => row.id,
	});

	const rowModel = table.getRowModel();
	const headerGroups = table.getHeaderGroups();
	const makeTestId = (id: string) => (testId ? `${testId}-${id}` : null);

	const { getIsColumnSortable, getIsColumnSorted, getSortHandler } =
		useDataTableSortHelpers({
			state: sort,
			onChange: onSortChange,
		});

	// The scrollable element for your list
	const parentRef = useRef<HTMLDivElement>(null);

	const virtualizer = useVirtualizer({
		count: data.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => ESTIMATED_ROW_HEIGHT,
		...options,
	});

	return (
		<div className="border border-muted rounded-md overflow-x-auto">
			<Table>
				{/* Header */}
				{headerGroups.map((headerGroup) => (
					<Table.Header data-testid={makeTestId("header")} key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							const { column } = header;
							const meta = getMetaWithDefaults(column);

							return (
								<Table.Column
									key={header.id}
									data-testid={makeTestId(`header-${header.id}`)}
									style={{ width: getColumnWidth(column) }}
									className={clsx(
										"overflow-hidden",
										meta.align === "left" && "text-left",
										meta.align === "center" && "text-center",
										meta.align === "right" && "text-right",
									)}
									sortable={getIsColumnSortable(column)}
									sorted={getIsColumnSorted(column)}
									onClick={getSortHandler(column)}
									sortDirection={sort?.direction}
								>
									{header.isPlaceholder
										? null
										: flexRender(column.columnDef.header, header.getContext())}
								</Table.Column>
							);
						})}
					</Table.Header>
				))}
				{/* Body */}
				{!isLoading && rowModel.rows.length !== 0 && (
					<div
						ref={parentRef}
						style={{
							minHeight: 0,
							maxHeight,
							overflow: "auto",
						}}
					>
						<div style={{ height: `${virtualizer.getTotalSize()}px` }}>
							<Table.Body>
								{virtualizer.getVirtualItems().map((virtualRow, index) => {
									const row = rowModel.rows[virtualRow.index];
									return (
										<Table.Row
											key={row.id}
											style={{
												height: `${virtualRow.size}px`,
												transform: `translateY(${
													virtualRow.start - index * virtualRow.size
												}px)`,
											}}
											data-testid={makeTestId(`row-${row.original.id}`)}
										>
											{row.getVisibleCells().map((cell) => {
												const { align = "left", truncate = false } =
													(cell.column.columnDef.meta as ColumnMeta<
														TData,
														any,
														TSortField
													>) ?? {};
												const columnWidth = cell.column.getSize();

												return (
													<Table.Cell
														key={cell.id}
														data-testid={makeTestId(`cell-${cell.column.id}`)}
														style={{
															width:
																columnWidth !== 150 ? columnWidth : undefined,
														}}
														className={clsx(
															"overflow-hidden",
															truncate && "truncate",
															align === "left" && "text-left",
															align === "center" && "text-center",
															align === "right" && "text-right",
														)}
													>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext(),
														)}
													</Table.Cell>
												);
											})}
										</Table.Row>
									);
								})}
							</Table.Body>
						</div>
					</div>
				)}
				{/* Empty State */}
				{!isLoading && rowModel.rows.length === 0 && (
					<Table.Body>
						<div className="flex min-h-[200px] items-center justify-center">
							{emptyState}
						</div>
					</Table.Body>
				)}
				{/* Loading State */}
				{isLoading ? (
					<Table.Body>
						{Array.from({ length: loadingRowCount }).map((_, index) => (
							<Table.Row
								// eslint-disable-next-line react/no-array-index-key
								key={`loading-${index}`}
							>
								{table.getAllColumns().map((column) => {
									const columnWidth = column.getSize();

									return (
										<Table.Cell
											key={column.id}
											style={{
												width: columnWidth !== 150 ? columnWidth : undefined,
											}}
										>
											<Skeleton className="my-[5px] flex h-[13px] items-center rounded-md" />
										</Table.Cell>
									);
								})}
							</Table.Row>
						))}
					</Table.Body>
				) : null}
			</Table>
		</div>
	);
}
