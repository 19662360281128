import clsx from "clsx";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Accept, FileRejection, useDropzone, ErrorCode } from "react-dropzone";

import { Button, ButtonSize, ButtonVariant } from "../../click/Button";
import { IconStatusWarningTriangle, IconUpload } from "../../icon";
import { FileCardList } from "./FileCardList";
import { FormField, FormFieldProps } from "../FormField";

type FileUploadChangeHandler<Multiple extends boolean> = Multiple extends true
	? (files: File[]) => void
	: (file: File) => void;

export type FileUploadProps<Multiple extends boolean = false> = {
	disabled?: boolean;
	hasError?: boolean;
	accept?: Accept;
	minSize?: number;
	maxSize?: number;
	maxFiles?: number;
	multiple?: Multiple;
	onChange?: FileUploadChangeHandler<Multiple>;
	onBlur?: () => void;
	id?: string;
	name?: string;
	initialFiles?: File[];
	initialFileRejections?: FileRejection[];
};

function getButtonLabel({
	fileCount,
	multiple = false,
}: {
	fileCount: number;
	multiple?: boolean;
}) {
	if (fileCount > 0) {
		return "Choose another file";
	}

	return multiple ? "Choose files" : "Choose a file";
}

function getUploaderLabel({
	hasTooManyFiles,
	multiple,
	maxFiles,
}: {
	hasTooManyFiles: boolean;
	multiple?: boolean;
	maxFiles?: number;
}) {
	if (hasTooManyFiles) {
		return multiple
			? `You can only upload ${maxFiles} files`
			: "You can only upload one file";
	}

	return multiple
		? "Drag and drop files here or"
		: "Drag and drop a file here or";
}

export const FileUpload = <Multiple extends boolean = false>({
	onChange,
	onBlur,
	multiple,
	disabled = false,
	hasError = false,
	id,
	name,
	accept,
	minSize,
	maxSize,
	maxFiles,
	initialFiles = [],
	initialFileRejections = [],
	...rest
}: FileUploadProps<Multiple>) => {
	const [files, setFiles] = useState<File[]>(initialFiles);
	const [fileRejections, setFileRejections] = useState<FileRejection[]>(
		initialFileRejections,
	);

	useEffect(() => {
		if (multiple) {
			// @ts-expect-error
			onChange?.(files ?? null);
		} else {
			// @ts-expect-error
			onChange?.(files[0] ?? null);
		}
	}, [files, onChange, multiple]);

	const handleDrop = useCallback(
		(acceptedFiles: File[] = [], newFileRejections: FileRejection[] = []) => {
			setFileRejections([]);
			if (multiple) {
				setFiles((existing) => [...existing, ...acceptedFiles]);
				setFileRejections((existing) => [...existing, ...newFileRejections]);
			} else {
				setFiles(acceptedFiles);
				setFileRejections(newFileRejections);
			}
			onBlur?.();
		},
		[multiple, onBlur],
	);

	const handleRemove = useCallback((file: File) => {
		setFiles((existing) => existing.filter((f) => f !== file));
		setFileRejections((existing) => existing.filter((r) => r.file !== file));
	}, []);

	const {
		isDragActive,
		isDragAccept,
		isDragReject,
		getRootProps,
		getInputProps,
	} = useDropzone({
		onDrop: handleDrop,
		disabled,
		multiple,
		accept,
		minSize,
		maxSize,
	});

	const hasFiles = files.length > 0;
	const hasFileRejections = fileRejections.length > 0;
	const canAcceptFiles = multiple || !hasFiles;
	const hasTooManyFiles =
		typeof maxFiles !== "undefined" &&
		files.length + fileRejections.length > maxFiles;

	return (
		<div
			className={clsx(
				"flex w-full flex-col items-center justify-center p-6",
				"rounded-md border",
				"transition-colors duration-200",
				disabled && "bg-form-field-disabled cursor-not-allowed",
				isDragActive && "opacity-50",
				isDragAccept && "bg-primary-50 border-primary border-solid",
				!isDragActive && [
					isDragReject || hasError || hasTooManyFiles
						? "border-form-field-error bg-form-field-error"
						: "border-form-field-default focus-within:border-form-field-highlight border-dashed bg-white opacity-100 focus-within:border-solid focus-within:bg-white",
				],
			)}
			{...rest}
			{...getRootProps()}
		>
			<input
				data-testid={`${id}-file-upload-input`}
				{...getInputProps({ id, name })}
			/>
			<div className="flex w-full flex-col">
				{(hasFiles || hasFileRejections) && (
					<FileCardList
						files={files}
						fileRejections={fileRejections}
						onRemove={handleRemove}
						minSize={minSize}
						maxSize={maxSize}
						accept={accept}
					/>
				)}
				<div className="mt-6 flex flex-col items-center justify-center">
					{canAcceptFiles && (
						<Fragment>
							{hasTooManyFiles ? (
								<IconStatusWarningTriangle className="text-3xl" />
							) : (
								<IconUpload
									className={clsx(
										"text-5xl",
										disabled ? "text-neutral-300" : "text-icon",
									)}
								/>
							)}
							<p
								className={clsx(
									"text-body-default mt-3",
									disabled ? "text-neutral-300" : "text-neutral-600",
								)}
							>
								{getUploaderLabel({ hasTooManyFiles, multiple, maxFiles })}
							</p>
						</Fragment>
					)}
					<Button
						className="mt-4"
						variant={ButtonVariant.Secondary}
						size={ButtonSize.Small}
						disabled={disabled}
						type="button"
					>
						{getButtonLabel({ fileCount: files.length, multiple })}
					</Button>
				</div>
			</div>
		</div>
	);
};

export type FileUploadFieldProps<Multiple extends boolean = false> =
	FileUploadProps<Multiple> & FormFieldProps;

export const FileUploadField = ({
	id,
	label,
	hint,
	error,
	tooltip,
	hasError,
	disabled,
	description,
	...props
}: FileUploadFieldProps) => (
	<FormField
		id={id}
		label={label}
		hint={hint}
		error={error}
		tooltip={tooltip}
		hasError={hasError}
		disabled={disabled}
		description={description}
	>
		<FileUpload id={id} {...props} />
	</FormField>
);

export { ErrorCode as FileUploadErrorCode };
